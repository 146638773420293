@font-face {
  font-family: "LatoRegular";
  src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
}
@font-face {
  font-family: "MarcellusRegular";
  src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
}

:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .breadcrumb {
    overflow: hidden;
  }
  
  .breadcrumb,
  .breadcrumb * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
.breadcrumb {
  gap: 40px;
  position: sticky;
  width: auto;
  height: 69px;
  margin: 0 auto;
  padding: 0px 70px;

  display: flex;
  height: 69px;
  padding: 0px 70px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  background: linear-gradient(0deg, #B3BEC5 0%, #B3BEC5 100%), #002;
}

.breadcrumb-navigation {
  height: 40px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -2px;
}

.breadcrumb-map-button {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 3px;
  position: relative;
  width: 87px;
  height: 40px;
  padding: 7px 21px;
  cursor: pointer;
  background: #ffffff;
  border: none;
  z-index: 17;
  border-radius: 15px 0 0 15px;
}


.breadcrumb-label {
  width: 45px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: LatoRegular;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-list-button {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 3px;
  position: relative;
  width: 87px;
  height: 40px;
  padding: 7px 17px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  z-index: 20;
  border-radius: 0 15px 15px 0;
}

.breadcrumb-label-list {
  width: 53px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: LatoRegular;
  color: #1d1b20;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-map-button.active {
  background: #1d1b20;
  color: #ffffff;
  border: none;
}

.breadcrumb-list-button.active {
  background: #1d1b20;
  color: #ffffff;
  border: none;
}

.breadcrumb-list-button.active .breadcrumb-label-list {
  color: #ffffff;
}

.breadcrumb-map-button.active .breadcrumb-label-map {
  color: #ffffff;
}

.icon.active-icon {
  background: url(../../assets/icons/icon.svg) no-repeat center;
  background-size: cover;
  width: 12.225px;
  height: 9.019px;
}


  .breadcrumb-date-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 18px;
    position: absolute;
    width: 146px;
    height: 40px;
    top: 15px;
    left: 310px;
    padding: 10px 32px 10px 32px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #000000;
    z-index: 4;
    border-radius: 25px;
  }
  .breadcrumb-date {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 17px;
    color: #393d44;
    font-family: Lato, var(--default-font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    white-space: nowrap;
    z-index: 5;
  }
  .breadcrumb-chevron-down {
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 6;
    overflow: hidden;
  }
  .breadcrumb-icon-date {
    position: relative;
    width: 16px;
    height: 16px;
    background: url(../../assets/icons/dashboard-home-page-chevron-down.svg)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 7;
  }
  .breadcrumb-time-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 18px;
    position: absolute;
    width: 148px;
    height: 40px;
    top: 15px;
    left: 463px;
    padding: 10px 32px 10px 32px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #000000;
    z-index: 8;
    border-radius: 25px;
  }
  .breadcrumb-time {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    color: #1e1e1e;
    font-family: Lato, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    z-index: 9;
  }
  .breadcrumb-chevron-time {
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 10;
    overflow: hidden;
  }
  .breadcrumb-icon-time {
    position: relative;
    width: 16px;
    height: 16px;
    background: url(../../assets/icons/dashboard-home-page-chevron-down.svg)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 11;
  }
  .breadcrumb-teams-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 18px;
    position: absolute;
    width: 130px;
    height: 40px;
    top: 15px;
    left: 672px;
    padding: 10px 32px 10px 32px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #000000;
    border-radius: 25px;
  }
  .breadcrumb-teams {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    color: #1e1e1e;
    font-family: Lato, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    z-index: 1;
  }
  .breadcrumb-chevron-teams {
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 2;
    overflow: hidden;
  }
  .breadcrumb-icon-teams {
    position: relative;
    width: 16px;
    height: 16px;
    background: url(../../assets/icons/dashboard-home-page-chevron-down.svg)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 3;
  }
  .breadcrumb-agents-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 18px;
    position: absolute;
    width: 131px;
    height: 40px;
    top: 15px;
    left: 809px;
    padding: 10px 32px 10px 32px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #000000;
    z-index: 12;
    border-radius: 25px;
  }
  .breadcrumb-agents {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 14px;
    color: #1e1e1e;
    font-family: Lato, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    white-space: nowrap;
    z-index: 13;
  }
  .breadcrumb-chevron-agents {
    flex-shrink: 0;
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 14;
    overflow: hidden;
  }
  .breadcrumb-icon-agents {
    position: relative;
    width: 16px;
    height: 16px;
    background: url(../../assets/icons/dashboard-home-page-chevron-down.svg)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 15;
  }
  .breadcrumb-create-task-button {
    width: 175px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #FAEF9B;
    text-align: center;
    background: #393D44;
    border-radius: 15px;
    cursor: pointer;
  }
  .breadcrumb-create-task {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 133px;
    height: 23px;
    color: #faef9b;
    font-family: Marcellus, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 22.57px;
    text-align: center;
    white-space: nowrap;
    z-index: 23;
  }
  
.task-form-close-button {
  backdrop-filter: blur(14px);
  margin-left: 50px;
}

.task-form-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-height: 100vh;
  overflow-y: scroll;
}

.task-form {
  display: flex;
  flex-direction: column;
}

.task-form h2 {
  margin-bottom: 10px;
}

.dates {
  height: 79px;
  z-index: 8;
  line-height: 2rem;
  position: relative;
  display: flex;               
  flex-direction: row;         
  align-items: center;          
  justify-content: space-between;
  gap: 16px;                    
  text-align: left;
  font-size: 14px;
  color: #393d44;
  font-family: Lato;
}

.dateOfTheBookingParent, .timeOfTheBookingParent {
  width: calc(50% - 8px);       
  position: relative;            
  height: 100%;                  
}

.formTextArea{
  width: 100%;
  position: relative;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  background-color: #fff;
  height: 122px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 15px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  font-family: Lato;
  border: none;
}

.topMarg {
  margin-top: 10px;
}

.topMargArea {
  margin-top: 20px;
}


.pickerActions{
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
  -webkit-box-pack: end;
  display: flex;
  background: white;
}

.action-timer {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  border: 0;
  border-radius: 4px;
  padding: 6px 8px;
  color: var(--variant-textColor);
  background-color: var(--variant-textBg);
  --variant-textColor: #8FA9B9;
  --variant-outlinedColor: #8FA9B9;
  --variant-outlinedBorder: rgba(25, 118, 210, 0.5);
  --variant-containedColor: #fff;
  --variant-containedBg: #8FA9B9;
}

.action-date {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  border: 0;
  border-radius: 4px;
  padding: 6px 8px;
  color: var(--variant-textColor);
  background-color: var(--variant-textBg);
  --variant-textColor: black;
  --variant-outlinedColor: black;
  --variant-outlinedBorder: rgba(25, 118, 210, 0.5);
  --variant-containedColor: #fff;
  --variant-containedBg: black;
}

.action:hover {
  --variant-containedBg: #1565c0;
  --variant-textBg: rgba(25, 118, 210, 0.04);
  --variant-outlinedBorder: #1976d2;
  --variant-outlinedBg: rgba(25, 118, 210, 0.04);
}

.time-icon {
  position: absolute;
  right: 20px;
  color: black; 
  font-weight: bold; 
  font-size: x-large;
  top: 45px;
}

.date-icon {
  position: absolute;
  right: 20px;
  color: black; 
  font-weight: bold; 
  font-size: x-large;
  top: 45px;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  display: none;
}

.date-picker span.MuiTypography-root.MuiTypography-overline {
  display: none;
}

.time-picker span.MuiTypography-root.MuiTypography-overline {
  position: relative;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  font-family: Roboto;
  color: #49454f;
  text-align: left;
}

.date-picker .MuiButtonBase-root.MuiPickersDay-root.Mui-selected  {
  color: #FAEF9B !important; 
}

.date-picker .Mui-selected {
  background-color: #393D44 !important; 
}

.time-picker .MuiClockPointer-root {
  background-color: #8FA9B9 !important; 
}

.time-picker .MuiClock-pin {
  background-color: #8FA9B9 !important; 
}

.time-picker .MuiClockPointer-thumb{
  background-color: transparent !important;
  border: 16px solid #8FA9B9 !important;
}

.background-breadcrumb {
  background: linear-gradient(0deg, #B3BEC5 0%, #B3BEC5 100%), #002;
}