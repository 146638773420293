/* Variables converted to actual color values */
:root {
  --green: #45C343;
  --wrapperColor: #EEEEEE;
  --containerColor: #FFFFFF;
  --black: #424242;
  --grayed: #A8A8A8;
  --titleBlack: #7B7B7B;
}

/* Timeline styles */
.timeline {
    min-width: 150px;
    min-height: 100px;
    position: absolute;
    padding: 20px 20px;
    top: -2px;
}

.timeline:before, .timeline:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--green);
}

.timeline:before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.timeline:after {
  bottom: 0;
  width: 2px;
}

.timeline-item {
    width: 100%;
    font-size: 0.9rem;
    margin: 45px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
}

.timeline-item > * {
  flex: 100px;
}

.timeline-item:last-child:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--green);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.timeline-item-title {
  text-align: right;
  color: var(--black);
}

.timeline-item-date {
  color: hsl(0, 0%, 70%);
}

.timeline-item-selector {
    background: var(--green);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    flex: 0 0 10px;
}

.timeline-item-selector:hover {
  transition: all 0.3s;
  background: var(--green);
  cursor: pointer;
}
