@font-face {
    font-family: "LatoRegular";
    src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
  }
  @font-face {
    font-family: "MarcellusRegular";
    src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
  }

.imageIcon {
    width: 63px;
    border-radius: 20px;
    height: 63px;
    object-fit: cover;
    z-index: 0;
    background: url(../../assets/images/dashboard-home-page-image.png) lightgray 50% / cover no-repeat;
    
}
.div {
    position: relative;
    font-weight: 500;
}
.rating {
    width: 81px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.rating1 {
    width: 85px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
    font-size: 14px;
}
.foremIpsum {
    align-self: stretch;
    position: relative;
    font-size: 18px;
    font-family: MarcellusRegular;
}
.rating2 {
    align-self: stretch;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
    font-size: 12px;
    color: #b3bec5;
}
.title {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
}
.text {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
}
.assigned {
    position: relative;
    text-transform: uppercase;
}
.tags1 {
    border-radius: 13px;
    background-color: #ffe3c1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
}
.tags {
    margin: 0 !important;
    position: absolute;
    top: 14px;
    left: 213px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    font-size: 12px;
    color: #ffa337;
}
.listItemWithImage {
    width: 100%;
    position: relative;
    border-radius: 20px;
    background-color: #f6f6f6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    gap: 10px;
    text-align: left;
    font-size: 11px;
    color: #393d44;
    font-family: LatoRegular;
}

