@font-face {
  font-family: "LatoRegular";
  src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
}
@font-face {
  font-family: "MarcellusRegular";
  src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
}

.list-item-with-image,
.list-item-with-image * {
  box-sizing: border-box;
}

.list-item-with-image {
display: flex;
border-radius: 20px;
height: 753px;
padding: 13px 16px 40px 16px;

flex-direction: column;
align-items: flex-start;
gap: 23px;
position: absolute;
left: 26px;

top: 176px;
background: var(--White-100, #FFF);
}

.frame-14 {
  display: flex;
  flex-direction: row;
  gap: 23px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 179px;
  position: relative;
}
.title {
  color: var(--primary-100, #393d44);
  text-align: left;
  width: 245px;
  flex-shrink: 0;
  font-family: MarcellusRegular;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: 245px;
}
.glyph {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  background: url("../../assets/icons/dashboard-home-page-glyph.svg") ;
  background-size: cover;
}
.tabs {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 298px;
  position: relative;
}

.itemSelected{
  border: 1px solid #8FA9B9;
}

.tab22 {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  flex: 1.5 1;
  height: 48px;
  position: relative;
  overflow: hidden;
}

.tab-group {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.tab-1 {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  position: relative;
  overflow: hidden;
}
.state-layer {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.tab-contents {
  padding: 14px 0px 14px 0px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.tab-group > div.active .label,
.tab-group > div.active .label2 {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: Lato;
  color: #1d1b20;
  text-align: center;
}
.label {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  font-family: Lato;
  color: #1d1b20;
  text-align: center;
}
.indicator {
  flex-shrink: 0;
  height: 14px;
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.shape {
  background: #1d1b20;
  height: 3px;
  position: absolute;
  right: 2px;
  left: 2px;
  bottom: 0px;
}
.tab-2 {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 48px;
  position: relative;
  overflow: hidden;
}
.label2 {
  color: #b3bec5;
  text-align: center;
  font-family: "Lato-Medium", sans-serif;
  font-size: var(--title-small-size, 14px);
  line-height: var(--title-small-line-height, 20px);
  letter-spacing: var(--title-small-tracking, 0.1px);
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-3 {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 48px;
  position: relative;
  overflow: hidden;
}
.divider {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.rectangle-13 {
  background: rgba(217, 217, 217, 0.5);
  flex-shrink: 0;
  width: 3px;
  height: 761px;
  position: absolute;
  left: 327px;
  top: -1px;
}
.rectangle-14 {
  background: #b3bec5;
  flex-shrink: 0;
  width: 3px;
  height: 239px;
  position: absolute;
  left: 327px;
  top: 115px;
}
.list-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  border-radius: 20px;
  position: sticky;

  max-height: 600px;
  overflow-y: auto;

  scrollbar-width: none; 
  -ms-overflow-style: none; 
}
.item {
  background: #f6f6f6;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  border-radius: 20px;
}

.item-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 195px;
  position: relative;
}

.date {
  text-align: left;
  font-family: "Lato-Medium", sans-serif;
  font-size: 11px;
  font-weight: 500;
  position: relative;
}
.date-span {
  color: #393d44;
  font-family: "Lato-Medium", sans-serif;
  font-size: 11px;
  font-weight: 500;
}
.date-span2 {
  color: #b3bec5;
  font-family: "Lato-Medium", sans-serif;
  font-size: 11px;
  font-weight: 500;
}
.date-span3 {
  color: #393d44;
  font-family: "Lato-Medium", sans-serif;
  font-size: 11px;
  font-weight: 500;
}
.item_title {
  color: var(--primary-100, #393d44);
  text-align: left;
  font-family: "Marcellus-Regular", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  align-self: stretch;
}
.rating2 {
  padding: 5px 0px 5px 0px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 14px;
  position: relative;
}
.item_description {
  color: #b3bec5;
  text-align: left;
  font-family: "Lato-Medium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}
.status-container {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0;
  position: absolute;
  left: 186px;
  top: 13.5px;
}
.status-badge {
  background: #b3bec5;
  border-radius: 13px;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.status-label {
  color: #1d1b20;
  text-align: left;
  font-family: var(--small-text-chip-font-family, "Lato-Bold", sans-serif);
  font-size: var(--small-text-chip-font-size, 12px);
  font-weight: var(--small-text-chip-font-weight, 700);
  text-transform: uppercase;
  position: relative;
}

