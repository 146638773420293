@font-face {
  font-family: "LatoRegular";
  src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
}
@font-face {
  font-family: "MarcellusRegular";
  src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
}

.header {
  overflow: hidden;
}

.header,
.header * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.header {
  display: flex;
  height: 80px;
  
  padding: 10px 70px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Flyspa-grey-40, rgba(179, 190, 197, 0.40));
  background: var(--White-100, #FFF);
}
.flyspa-logo {
  width: 110px;
  height: 55.019px;
  background: url(../../assets/images/dashboard-home-page-flyspa-logo.svg)
    no-repeat center;
  background-size: 100% 100%;
}
.avatar {
  flex-shrink: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 122px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 0;
  overflow: hidden;
}
.avatar-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #EEDD5F
    no-repeat center;
  background-size: 100% 100%;
  z-index: 8;
  border-radius: 50%;
}

.avatar-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  color: #1d1b20;
  font-family: Roboto, var(--default-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  z-index: 9;
}
.header-title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: absolute;
  width: 104px;
  height: 25px;
  top: 28.5px;
  left: 233px;
  color: var(--Primary-100, #393D44);
  font-family: MarcellusRegular;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  bottom: 26.5px;
}
.icon-notif {
  background: url(../../assets/images/dashboard-home-page-group-34.svg)
  no-repeat center;
  width: 27px;
  height: 27px;
  position: absolute;
  right: 186px;
  top: 26px;
  cursor: pointer;
}

.icon-small {
  position: absolute;
  right: 73px;
  background: url(../../assets/images/dashboard-home-page-group-35.svg)
    no-repeat center;
  width: 15px;
  height: 12px;
  cursor: pointer;
}
